import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import DestaqueHome from "../components/home/destaque-home.component";
import CategoriasSections from "../components/home/categorias-sections.component";
import FemininaHomeSection from "../components/home/feminina-home-section.component";
import ColecaoHome from "../components/home/colecao-home.component";
import ContatoBottom from "../components/contato-bottom.component";
import {addCurrentPage, loadTopOn, showModalMenu} from "../redux/utils/utils.actions";
import {selectUtilsLoadersTop} from "../redux/utils/utils.selectors";
import useImagePreloader from "../hooks/use-image-preloader.component";

import bgHomeDestaque from '../assets/imgs/bg-home-destaque.jpg';
import modelosTop from '../assets/imgs/modelos-home-top.png';


const HomePage = (props) => {


    useEffect(() => {

        scrollToTop()
        props.addCurrentPage("home");

        props.setLoadTop(true)

        const imgs = [
            bgHomeDestaque,
            modelosTop
        ]

        const loadImage = image => {

            return new Promise((resolve, reject) => {
                const loadImg = new Image()
                loadImg.src = image
                // wait 2 seconds to simulate loading time
                loadImg.onload = () =>
                    setTimeout(() => {
                        resolve(image)
                    }, 0)
                loadImg.onerror = err => reject(err)
            })
        }

        Promise.all(imgs.map(image => loadImage(image)))
            .then(() => props.setLoadTop(false))
            .catch(err => props.setLoadTop(false))

    }, []);


    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "instant"
        });
    }

    return(

        <>
            {!props.loadTop ?
                <>
                    <DestaqueHome bgHomeDestaque={bgHomeDestaque} modelosTop={modelosTop}/>
                    <CategoriasSections/>
                    <FemininaHomeSection />
                    <ColecaoHome />
                    <ContatoBottom />
                </>
                :
                <div style={{height: 1500}}/>
            }
        </>
    );

}

const mapStateToProps = state => ({
    loadTop: selectUtilsLoadersTop(state)
})

const mapDispatchToProps = dispatch => ({
    addCurrentPage: utils => dispatch(addCurrentPage(utils)),
    setLoadTop: utils => dispatch(loadTopOn(utils))
})

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);