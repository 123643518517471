import React, {useEffect} from "react";
import { connect } from "react-redux";
import ContatoBottom from "../components/contato-bottom.component";
import {addCurrentPage} from "../redux/utils/utils.actions";

const ContatoPage = (props) => {

    useEffect(() => {
        scrollToTop()
        props.addCurrentPage("contato");

    }, []);

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "instant"
        });
    }

    return(
        <>
            <ContatoBottom type="contato"/>
        </>
    )

}

const mapDispatchToProps = dispatch => ({
    addCurrentPage: utils => dispatch(addCurrentPage(utils))
})

export default connect(null, mapDispatchToProps)(ContatoPage);
