import React from "react";
import bgHomeDestaque from '../../assets/imgs/bg-home-destaque.jpg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import modelosTop from '../../assets/imgs/modelos-home-top.png';



const DestaqueHome = ({bgHomeDestaque, modelosTop}) => {

    return(
        <>
            <section
                id="destaque"
                className="primeira-ses destaque"
                style={{ background: ` url(${bgHomeDestaque}) center / cover `, backgrpundRepeat: 'no-repeat' }}
            >
                <div className="row no-h-margins">
                    <div className="col">
                        <h2 data-aos="fade-right" data-aos-once="true" style={{ textAlign: 'center' }}>
                            Desde &nbsp;<span>&nbsp;1991</span> Produzindo
                        </h2>
                        <h1 data-aos="zoom-in" data-aos-delay="50" data-aos-once="true" style={{ textAlign: 'center' }}>
                            <span>&nbsp;Qualidade e Estilo</span>
                        </h1>
                        <div data-aos="fade-left" data-aos-delay="150" data-aos-once="true" className="btn-top">
                            <h4 className="text-center">
                                <FontAwesomeIcon icon={faPhoneVolume} />&nbsp; &nbsp;<strong>(11) 93149-6208 / (11) 99621-3019&nbsp; &nbsp;</strong>
                                <FontAwesomeIcon icon={faWhatsapp} />
                            </h4>
                        </div>
                        <div data-aos="fade-left" data-aos-delay="150" data-aos-once="true"
                             className="btn-top-mbl aos-init aos-animate">
                            <h4 className="text-center"><FontAwesomeIcon icon={faPhoneVolume} />&nbsp; &nbsp;<strong>(11)
                                93149-6208</strong><br/>
                                <strong>&nbsp;</strong><FontAwesomeIcon icon={faWhatsapp} />
                                <strong>&nbsp; (11) 99621-3019</strong>
                            </h4>
                        </div>
                        <div className="sep"></div>
                    </div>
                    <div className="col d-flex justify-content-end col-right">
                        <img data-aos="fade-left"
                             data-aos-delay="200"
                             data-aos-once="true"
                             src={modelosTop}
                        />
                    </div>
                </div>
            </section>
        </>
    );

}

export default DestaqueHome;