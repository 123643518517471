import React, { useState } from "react";
import { connect } from "react-redux";
import {addCurrentProd, showModalProd} from "../redux/utils/utils.actions";
import {Spinner} from "react-bootstrap";

import placeholderImg from '../assets/imgs/placeholder-img-prods.jpg';

const BoxPordutos = (props) => {

    const [loaded, setLoaded] = useState(false);

    const produto = props.produto

    const handleClick = () => {
        props.addCurrentProd(props.produto);
        props.showModalProd(true);
    }

    const handleLoaded = () => {
        setLoaded(true);
    }

    return(

        <div className="col d-flex justify-content-center align-items-center">
            <a style={{ cursor: 'pointer' }}
               onClick={handleClick}
            >
                <div className="home-box-prods transit-400">
                    <img src={produto.imgCapa} onLoad={handleLoaded}/>
                    { !loaded ?

                        <>
                            <img src={placeholderImg}/>
                            <div className="overlay">
                                <Spinner animation="border"  />
                            </div>
                        </>

                        : null }

                    <div className="title">
                        <h2>{produto.nome}</h2>
                    </div>
                </div>
            </a>
        </div>

    );

}

const mapDispatchToProps = dispatch => ({
    showModalProd: utils => dispatch(showModalProd(utils)),
    addCurrentProd: utils => dispatch(addCurrentProd(utils))
})



export default connect(null, mapDispatchToProps)(BoxPordutos);