import React, {useEffect} from "react";
import { connect } from "react-redux";
import SessionTop from "../components/session-top.component";
import bgTop from '../assets/imgs/quem-somos-gg.jpg';
import detalheVerde from '../assets/imgs/detalhe-verde-inverted.png';
import {addCurrentPage, loadTopOn} from "../redux/utils/utils.actions";
import ContatoBottom from "../components/contato-bottom.component";
import {selectUtilsLoadersTop} from "../redux/utils/utils.selectors";


const QuemSomosPage = (props) => {


    useEffect(() => {

        scrollToTop()
        props.addCurrentPage("quem-somos");

    }, []);

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "instant"
        });
    }

    return(
        <>

            <SessionTop bgTop={bgTop} flex={true}>
                <div className="container-fluid container-s-tops" style={{position: 'relative', zIndex: '11'}}>
                    <div className="row">
                        <div className="col-12" data-aos="fade-right" data-aos-once="true">
                            <h1 className="transit-400">QUEM SOMOS</h1>
                        </div>
                    </div>
                </div>
            </SessionTop>

            <section className="s-colecao">
                <div className="container quem-somos">
                    <div className="row">
                        <div className="col">
                            <h1 className="text-center h1-gg" data-aos="zoom-in"
                                data-aos-once="true">
                                Desde 1991 no mercado<br/>
                            </h1>
                            <h3 className="text-center h3-gg" data-aos="zoom-out"
                                data-aos-once="true">
                                Somos uma empresa voltada para o mercado de produtos&nbsp;para
                                motociclistas, trabalhando desde 1991 procurando sempre oferecer a nossos clientes
                                produtos com a mais alta qualidade e durabilidade, pórem com preços acessíveis o que nos
                                tem colocado como marca de destaque em nosso seguimento.<br/>
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="bottom-detail"
                     style={{ background: `url(${detalheVerde}) center`, backgroundRepeat: "no-repeat" }}
                />
            </section>

            <ContatoBottom />

        </>
    );

}

const mapStateToProps = state => ({
    loadTop: selectUtilsLoadersTop(state)
})

const mapDispatchToProps = dispatch => ({
    addCurrentPage: utils => dispatch(addCurrentPage(utils)),
    setLoadTop: utils => dispatch(loadTopOn(utils))
})

export default connect(mapStateToProps, mapDispatchToProps)(QuemSomosPage);

