import React from "react";
import {HOME_CATS} from "../../utils/datas";
import CategoriasHome from "./categorias-home.component";

const CategoriasSections = (props) => {

    const catsTop = HOME_CATS
    let delayG = 0;

    return(
        <>
            <section>
                <div className="home-bg-dest-cats">
                    <div className="container inner-container">
                        <div className="row g-5 row-cats-home">

                            {
                                catsTop.map(item => {

                                    delayG += 50;
                                    return(
                                        <CategoriasHome key={item.id} item={item} delay={delayG} />
                                    );
                                })
                            }

                        </div>
                    </div>
                </div>
            </section>
        </>
    );

}

export default CategoriasSections;