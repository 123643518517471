import React, {useEffect, useRef, useState} from "react";
import detalheBottom from "../assets/imgs/detalhe-verde-top-outros.png";
//import { Parallax, ParallaxLayer, IParallax } from 'react-spring';

import '../assets/css/parallax-teste.css';

const SessionTop = (props) => {


    const [offsetY, setOffsetY] = useState(0);
    const handleScroll = () => setOffsetY(window.pageYOffset);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, []);


    return(
        <section className={`${props.flex ? 'd-xl-flex ' : '' } s-tops primeira-ses`}>

            { props.children }

            <div
                className="Parallax__background"
                style={{
                    backgroundImage: `url(${props.bgTop})`,
                    transform: `translate3d(0px, ${offsetY * 0.2}px , 0px)` }}
            />


            <div className="bottom-details"
                 style={{ background: `url(${ detalheBottom }) center` }}
            />
        </section>
    );

}


export default SessionTop;