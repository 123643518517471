import React, {useState} from "react";
import { connect } from "react-redux";
import ReactHtmlParser  from 'react-html-parser';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {selectCurrentProd} from "../../redux/utils/utils.selectors";
import {addCurrentProd, showModalProd} from "../../redux/utils/utils.actions";
import { ModalBody } from "react-bootstrap";
import ImageGallery from "react-image-gallery";

import detalheTop from '../../assets/imgs/detalhe-verde-bottom.png';
import bgModal from '../../assets/imgs/bg-prods.jpg';


import 'react-image-gallery/styles/css/image-gallery.css';
import '../../assets/css/gallery-fix.css';

const ModalProduto = (props) => {

    const [full, setFull] = useState(false);
    const prod = props.currentProd;

    const handleClose = () => {
        props.showModalProd(false);
        // props.addCurrentProd(null)

    }

    const handleImgClick = (event) => {

        console.log(event.target.src)
    }

    const hanleFullscrenn = () => {
        setFull(!full);

    }


    return(
        <ModalBody style={{ background: `url(${bgModal}) center / cover`, backgroundRepeat: 'no-repeat' }}>
            <button
                className="btn btn-close-modal-prods"
                type="button"
                onClick={handleClose}
            >
                <FontAwesomeIcon icon={faTimes} />
            </button>
            <div className="dtl-top"
                 style={{ background: `url(${detalheTop}) top / cover`, backgroundRepeat: "no-repeat" }}
            />

            <div className="container-title title-colecao">
                <div data-aos="zoom-in" data-aos-once="true" className="box-title">
                    <h2>{ prod.nome.toUpperCase() }</h2>
                </div>
            </div>

            <div className="container">
                <div className="row g-5 d-flex justify-content-center ">
                    <div className="col-12 col-lg-6 ">
                        {
                            prod.imgs.length > 0 ?
                                <div className="col-white" style={{paddingTop: '20px', paddingBottom: '60px'}}>
                                    <ImageGallery
                                        items={prod.imgs}
                                        onClick={handleImgClick}
                                        onScreenChange={hanleFullscrenn}
                                        additionalClass={full ? '' : "gallery-maxh"}
                                        showThumbnails={false}
                                        showPlayButton={false}
                                        showBullets={true}
                                    />
                                </div>
                                :

                                <div className="col-white" style={{padding: '20px'}}>
                                    <img className="img-fluid" src={prod.imgCapa}/>
                                </div>
                        }

                    </div>

                    { prod.detalhes !== "" ?
                        <div className="col-12 col-lg-6 col-white">
                            <div className="details">
                                <h2>DETALHES</h2>
                                <p>
                                    {ReactHtmlParser(prod.detalhes)}
                                </p>
                            </div>
                        </div>
                        : null
                    }

                </div>
            </div>
        </ModalBody>
    );

}

const mapStateToProps = state => ({
    currentProd: selectCurrentProd(state)
})

const mapDispatchToProps = dispatch => ({
    showModalProd: utils => dispatch(showModalProd(utils)),
    addCurrentProd: utils => dispatch(addCurrentProd(utils))
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalProduto);