import HomePage from "./pages/home-page.component";
import QuemSomosPage from "./pages/quem-somos-page.component";
import ProdutosPage from "./pages/produtos-page.component";
import ContatoPage from "./pages/contato-page.component";
import ColecaoPage from "./pages/colecao-page.component";

const myRoutes = [
    {
        path: '/',
        element: <HomePage />
    },
    {
        path: '/quem-somos',
        element: <QuemSomosPage />
    },
    {
        path: '/produtos',
        element: <ProdutosPage />
    },
    {
        path: '/produtos/:id',
        element: <ProdutosPage />
    },
    {
        path: '/contato',
        element: <ContatoPage />
    },
    {
        path: '/nova-colecao',
        element: <ColecaoPage />
    }

]

export default myRoutes;