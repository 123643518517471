import { UtilsActionTypes } from './utils.types';

export const loadTopOn= utils => ({
    type: UtilsActionTypes.LOADING_TOP_ON,
    payload: utils
});

export const showModalMenu = utils => ({
    type: UtilsActionTypes.MODAL_MENU,
    payload: utils
});

export const showModalProd = utils => ({
    type: UtilsActionTypes.MODAL_PROD,
    payload: utils
});

export const addCurrentProd = utils => ({
    type: UtilsActionTypes.CURRENT_PROD,
    payload: utils
});

export const addCurrentPage = utils => ({
    type: UtilsActionTypes.CURRENT_PAGE,
    payload: utils
});
