import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useSpring, animated } from 'react-spring';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import logo from '../assets/imgs/logo-top.png';
import brasilFlag from '../assets/imgs/icon_brasil.jpg';
import {selectCurrentPage, selectUtilsLoadersTop} from "../redux/utils/utils.selectors";
import {addCurrentPage, showModalMenu} from "../redux/utils/utils.actions";



const Header = (props) => {

    const [scrolledClass, setScrolledClass] = useState(false);
    const [animatedLoad, setAnimatedLoad] = useState(false);
    const [currentLink, setCurrentLink] = useState("home");

    const propsAnimation = useSpring({
        opacity: !props.loaderTopOn ? 0 : 1,
        top: !props.loaderTopOn ? -40:  scrolledClass ? 78 : 160,
        config: { duration: 400}

    })

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        return () => {
            //console.log("Bye");
            window.removeEventListener('scroll', handleScroll)
        };
    }, []);

    const handleScroll = () => {
        window.onscroll = () => {

            if(window.pageYOffset > 114)
            {
                setScrolledClass(true)
            }
            else
            {
                setScrolledClass(false)
            }
        }
    }

    const handleOpenModal= () => {
        props.showModalMenu(true);
    }

    return(
        <>
            <animated.div style={propsAnimation} className='top-loader commom-shadown'>
                <Spinner animation="border"  />
            </animated.div>
            <header className="header-top transit-400">
                <div className={`div-ind-top transit-400 ${ scrolledClass ? 'div-ind-top-scroll' : null }`}>
                    <h2><img src={brasilFlag} /> <br/>INDÚSTRIA <br/>BRASILEIRA</h2>
                </div>
                <div className="container-fluid" style={{ background: '#fff' }}>
                    <div className="row">
                        <div className="col-3 transit-400">
                            <img className={`logo-top transit-400 ${ scrolledClass ? 'logo-scroll' : null }`} src={logo}/>
                        </div>
                        <div className="col d-flex justify-content-end">
                            <nav className="navbar navbar-light navbar-expand-md d-lg-flex navbar-top transit-400">
                                <div className="container-fluid">
                                    <div className="collapse navbar-collapse" id="navcol-1">
                                        <ul className="navbar-nav">
                                            <li className="nav-item">
                                                <Link
                                                    className={`nav-link transit-400 ${ props.currentPage === 'home' ? 'active' : ''  }`}
                                                    to="/"
                                                    onClick={() => props.addCurrentPage('home')}
                                                >
                                                    Home
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    className={`nav-link transit-400 ${ props.currentPage === 'quem-somos' ? 'active' : ''  }`}
                                                    to="quem-somos"
                                                    onClick={() => props.addCurrentPage('quem-somos')}
                                                >
                                                    Quem Somos
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    className={`nav-link transit-400 ${ props.currentPage === 'produtos' ? 'active' : ''  }`}
                                                    to="produtos/jaquetas-masculinas"
                                                    onClick={() => props.addCurrentPage('produtos')}
                                                >
                                                    Produtos
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    className={`nav-link transit-400 ${ props.currentPage === 'contato' ? 'active' : ''  }`}
                                                    to="contato"
                                                    onClick={() => props.addCurrentPage('contato')}
                                                >
                                                    Contato
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className={`header-sep-verde transit-400 ${ scrolledClass ? 'header-sep-verde-scroll' : null }`} />
                <div className={`header-sep-branco transit-400 ${ scrolledClass ? 'header-sep-branco-scroll' : null } `}
                     style={{ boxShadow: '2px 2px 5px rgba(31,31,31,0.25)' }}
                />
                <div className="hamb-div" onClick={handleOpenModal}>
                    <FontAwesomeIcon icon={faBars} />
                </div>
            </header>
        </>
    );

}

const mapStateToProps = state => ({
    loaderTopOn: selectUtilsLoadersTop(state),
    currentPage: selectCurrentPage(state)
});

const mapDispatchToProps = dispatch => ({
    showModalMenu: utils => dispatch(showModalMenu(utils)),
    addCurrentPage: utils => dispatch(addCurrentPage(utils))
})


export default connect(mapStateToProps, mapDispatchToProps)(Header);