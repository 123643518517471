import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";

import {NOVA_COLECAO} from "../../utils/datas";

import BoxPordutos from "../box-produtos.component";



import detalheVerdeTop from '../../assets/imgs/detalhe-verde-inverted-bottom-2.png';
import detalheVerdeBottom from '../../assets/imgs/detalhe-verde-inverted.png';

const ColecaoHome = (props) => {

    let delayG = 0;
    const novaColecao = NOVA_COLECAO;

    return(
        <section className="s-colecao">
            <div className="top-detail"
                 style={{ background: `url(${detalheVerdeTop}) center`, backgroundRepeat: "no-repeat" }}
            />
            <div className="top-sep" />
            <div className="container-title title-colecao">
                <div className="box-title">
                    <h2>Produtos</h2>
                </div>
            </div>
            <div className="container container-colecao">
                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-2">

                    {
                        novaColecao.map(item => {
                            delayG += 50;
                            return( <BoxPordutos key={item.id} produto={item} delay={delayG} /> );
                        })
                    }

                </div>
            </div>
            <div className="d-flex justify-content-center hold-bt-green-home aos-init aos-animate" data-aos="zoom-out"
                 data-aos-once="true">
                <div className="btn-grenn-gg">
                    <Link className="transit-400" to="/produtos/jaquetas-masculinas">Veja Mais</Link>
                    <FontAwesomeIcon icon={faLongArrowAltRight} className='transit-400'/>
                </div>
            </div>
            <div className="bottom-detail"
                 style={{ background: `url(${detalheVerdeBottom}) center`, backgroundRepeat: "no-repeat" }}
            />
        </section>
    );

}

export default ColecaoHome;