import { createSelector } from 'reselect';

const selectUtils = state => state.utils;

export const selectUtilsLoadersTop = createSelector(
    [selectUtils],
    utils => utils.loadTop
);

export const selectModalMenu = createSelector(
    [selectUtils],
    utils => utils.modalMenu
);

export const selectModalProd = createSelector(
    [selectUtils],
    utils => utils.modalProd
);

export const selectCurrentProd = createSelector(
    [selectUtils],
    utils => utils.currentProd
);

export const selectCurrentPage = createSelector(
    [selectUtils],
    utils => utils.currentPage
);
