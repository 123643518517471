import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {showModalMenu} from "../../redux/utils/utils.actions";

const ModalMenu = (props) => {

    const navigate = useNavigate();

    const handleClick = (to) => {
        props.showModalMenu(false);
        navigate(to);
    }

    const handleClose = () => {
        props.showModalMenu(false);
    }

    return(
        <>
            <button className="btn btn-close-modal-menu" type="button" onClick={handleClose}>
                <FontAwesomeIcon icon={faTimes} />
            </button>

            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <div className="side-left-col-modal">
                            <div style={{ height: '56px', marginBottom: '25px' }}>
                                <h2
                                    style={{
                                        paddingLeft: '16px',
                                        paddingTop: '15px',
                                        fontWeight: "bold",
                                        color: '#fff',
                                        paddingBottom: '0px',
                                        marginBottom: '0px',
                                        fontFamily: 'Agency FB',
                                        fontSize: '38px'
                                    }}
                                >
                                    Menu
                                </h2>
                            </div>
                            <ul className="list-group list-group-ath-left">

                                <li className="list-group-item transit-400">
                                    <a className="d-flex flex-row" onClick={() => handleClick('/')} style={{ cursor: 'pointer' }}>
                                        <span className="transit-400">Home</span>
                                    </a>
                                </li>

                                <li className="list-group-item transit-400">
                                    <a className="d-flex flex-row" onClick={() => handleClick('/quem-somos')} style={{ cursor: 'pointer' }}>
                                        <span className="transit-400">Quem Somos</span>
                                    </a>
                                </li>

                                <li className="list-group-item transit-400">
                                    <a className="d-flex flex-row" onClick={() => handleClick('/produtos/jaquetas-masculinas')} style={{ cursor: 'pointer' }}>

                                        <span className="transit-400">Produtos</span>
                                    </a>
                                </li>

                                <li className="list-group-item transit-400">
                                    <a className="d-flex flex-row" onClick={() => handleClick('/contato')} style={{ cursor: 'pointer' }}>

                                        <span className="transit-400">Contato</span>
                                    </a>
                                </li>


                            </ul>
                            <div  style={{height: '20px'}}/>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}

const mapDispatchToProps = dispatch => ({
    showModalMenu: utils => dispatch(showModalMenu(utils))
})

export default connect(null, mapDispatchToProps)(ModalMenu);