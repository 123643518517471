import React from "react";
import { PRODUTOS } from "../../utils/datas";
import BoxPordutos from "../box-produtos.component";
import detalheVerdeBottom from "../../assets/imgs/detalhe-verde-inverted.png";

const Categoria = (props) => {

    const cat = props.categoria[0];
    const arrayProdutos = PRODUTOS;
    let delayG = 0;

    function produtos(v) {
        return arrayProdutos.filter(item => item.cat === v);
    }

    return(
        <section className="s-colecao produtos">
            <div className="container-title title-colecao">
                <div data-aos="zoom-in" data-aos-once="true" className="box-title produtos">
                    <h2>{cat.nomeCat.toUpperCase()}</h2>
                </div>
            </div>

            <div className="container container-colecao">
                <div className="row row-cols-1 row-cols-lg-3 justify-content-center">

                    {
                        produtos(cat.url).length > 0 ?
                        produtos(cat.url).map(item => {
                            delayG += 50;
                            return( <BoxPordutos key={item.id} produto={item} delay={delayG} /> );
                        })
                            :
                            <>
                                <div className="col-12">
                                    <h2 style={{textAlign: 'center'}}>NENHUM PRODUTO ENCONTRADO</h2>
                                </div>

                            </>
                    }

                </div>

            </div>
            <div className="bottom-detail"
                 style={{ background: `url(${detalheVerdeBottom}) center`, backgroundRepeat: "no-repeat" }}
            />
        </section>
    );

}

export default Categoria;