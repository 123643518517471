import React from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser  from 'react-html-parser';


const BoxMinProdutos = (props) => {

    const cat = props.categoria;
    return(
        <Link to={`/produtos/${cat.url}`} onClick={props.onClick}>
            <div className={`box-min-prods transit-400 ${ cat.active ? 'active' : '' }`} >

                <img className="transit-400" src={cat.img}/>
                <div className="d-flex justify-content-center align-items-center title2">
                    <h2>{ReactHtmlParser(cat.nome.toUpperCase())}</h2>
                </div>
            </div>
        </Link>
    );

}

export default BoxMinProdutos;