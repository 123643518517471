import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useRoutes } from "react-router-dom";

import myRoutes from "./routes";
import Aos from 'aos';

import Header from "./components/header.component";
import Footer from "./components/footer.component";
import { Modal } from "react-bootstrap";
import {selectCurrentProd, selectModalMenu, selectModalProd} from "./redux/utils/utils.selectors";

import 'aos/dist/aos.css';
import ModalMenu from "./components/modal/modal-menu-component";
import ModalProduto from "./components/modal/modal-produto.component";


const App = (props) => {

    const appRoutes = useRoutes(myRoutes);

    useEffect(() => {
        Aos.init({ duration: 500 });
    }, [])

    return (
        <>
            <Header />
            {appRoutes}
            <Footer />

            <Modal show={props.showModalMenu} dialogClassName={"modal-dialog modal-menu modal-dialog-centered modal-fullscreen"}>
                <ModalMenu />
            </Modal>

            <Modal show={props.showModalProd} dialogClassName={"modal-dialog modal-prods modal-dialog-centered modal-dialog-scrollable modal-fullscreen"}>
                <ModalProduto />
            </Modal>

        </>
    );
}

const mapStateToProps = state => ({
    showModalMenu: selectModalMenu(state),
    showModalProd: selectModalProd(state)
})

export default connect(mapStateToProps)(App);
