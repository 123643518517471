import { UtilsActionTypes } from "./utils.types";

const INITIAL_STATE = {
    loadTop: false,
    currentProd: null,
    modalProd: false,
    modalMenu: false,
    currentPage: ""
}

const utilsReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case UtilsActionTypes.LOADING_TOP_ON:
            return {
                ...state,
                loadTop: action.payload
            }

        case UtilsActionTypes.MODAL_MENU:
            return {
                ...state,
                modalMenu: action.payload
            }

        case UtilsActionTypes.MODAL_PROD:
            return {
                ...state,
                modalProd: action.payload
            }

        case UtilsActionTypes.CURRENT_PROD:
            return {
                ...state,
                currentProd: action.payload
            }

        case UtilsActionTypes.CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            }

        default:
            return state;

    }
}

export default utilsReducer;