import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useParams, Outlet } from "react-router-dom";
import SessionTop from "../components/session-top.component";
import bgTop from "../assets/imgs/produtos-gg.jpg";
import {CATEGORIAS} from "../utils/datas";
import BoxMinProdutos from "../components/produtos/box-min-produtos.component";
import ContatoBottom from "../components/contato-bottom.component";
import Categoria from "../components/produtos/categoria.component";
import {addCurrentPage, loadTopOn} from "../redux/utils/utils.actions";
import useImagePreloader from "../hooks/use-image-preloader.component";
import {selectUtilsLoadersTop} from "../redux/utils/utils.selectors";



const ProdutosPage = (props) => {


    const params = useParams();
    const [cats, setCats] = useState(CATEGORIAS);
    const [currentCat, setCurrentCat] = useState(fCurrentCat(params.id));

    const [carregouImgs, setCarregouImgs] = useState(false);

    useEffect(()=> {

        setCurrentCat(fCurrentCat(params.id))
        changeCat(params.id);

        if (!carregouImgs)
        {
            scrollToTop()
            props.setLoadTop(true);
            props.addCurrentPage("produtos");

            const loadImage = image => {
                return new Promise((resolve, reject) => {
                    const loadImg = new Image()
                    loadImg.src = image.img
                    // wait 2 seconds to simulate loading time
                    loadImg.onload = () =>
                        setTimeout(() => {
                            resolve(image.img)
                        }, 0)

                    loadImg.onerror = err => reject(err)
                })
            }

            Promise.all(cats.map(image => loadImage(image)))
                .then(() => {
                        props.setLoadTop(false);
                    }
                )
                .catch(err => props.setLoadTop(false))
            setCarregouImgs(true);
        }


    }, [params.id])

    function fCurrentCat(url)
    {
        const exist = cats.find(item => item.url === url);
        if(exist)
        {
            return cats.filter(item => item.url === url);
        }
        else
        {
            return null;
        }
    }

    function changeCat(url)
    {
        const exist = cats.find(item => item.url === url);

        if(exist)
        {

            setCats(
                cats.map(item => {
                    if(item.url === url)
                    {
                        return {...item, active: true}
                    }
                    else
                    {
                        return {...item, active: false}
                    }

                })
            );

        }

    }

    const handleCatClick = (valor) => {

        changeCat(valor)
        setCurrentCat(fCurrentCat(valor))
    }

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "instant"
        });
    }


    return(
        <>
            {!props.loadTop ?
                <>
                    <SessionTop bgTop={bgTop} flex={false}>
                            <div className="container-fluid container-s-tops produtos" style={{position: 'relative', zIndex: '11'}}>
                                <div className="row">
                                    <div className="col-12" data-aos="fade-right" data-aos-once="true">
                                        <h1 className="transit-400">PRODUTOS</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center flex-wrap div-box-prods"
                                 data-aos="fade-up"
                                 data-aos-once="true"
                            >

                                {
                                    cats.map(item => {

                                        return(
                                            <BoxMinProdutos key={item.id} categoria={item} onClick={() => handleCatClick(item.url)} />
                                        )

                                    })
                                }
                            </div>
                        </SessionTop>
                    <Categoria categoria={currentCat} />
                    <ContatoBottom />
                </>
                :
                <div style={{height: 1500}}/>
            }
        </>
    );


}

const mapStateToProps = state => ({
    loadTop: selectUtilsLoadersTop(state)
})

const mapDispatchToProps = dispatch => ({
    addCurrentPage: utils => dispatch(addCurrentPage(utils)),
    setLoadTop: utils => dispatch(loadTopOn(utils))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProdutosPage);
