import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./redux/store";

import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/AgencyFB.css';
import './assets/css/Agency FB.css';
import './assets/css/styles.css';
import './assets/css/styles-mbl.css';
import 'animate.css/animate.min.css';

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </BrowserRouter>
    </Provider>
    ,
    document.getElementById('root')
);

