import React from "react";
import logoBF from '../assets/imgs/logo-bf.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";

const Footer = () => (
    <>
        <footer className="footer-home">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <p><FontAwesomeIcon icon={faCopyright}/>&nbsp; &nbsp;Copyright 2021 - Jaqueta Arizona.</p>
                    </div>
                    <div className="col-12 col-lg-6 d-flex justify-content-xl-end">
                        <p>&nbsp; &nbsp;Desenvolvido por:&nbsp;</p>
                        <a href="https://businessforce.com.br/"
                           target="_blank"
                        >
                            <img src={logoBF} style={{marginTop: '6px'}}/>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    </>
)

export default Footer;