import React from "react";
import { Link } from "react-router-dom";

const CategoriasHome = ({item, delay}) => {


    return(
        <>
            <div className="col-12 col-lg-4" data-aos="fade-up"
                 data-aos-once="true"
                 data-aos-delay={delay}
            >
                <div className="box-cat-home transit-400">
                    <img className="img-fluid"
                         src={item.img}
                    />
                    <div className="title transit-400">
                        <h4 className="text-center">{item.nome}</h4>
                    </div>
                    <Link to={item.link} />
                </div>
            </div>
        </>
    );

}

export default CategoriasHome;