import React from "react";
import { Link } from "react-router-dom";
import bgFeminina from '../../assets/imgs/bg-home-feminino.jpg';
import jaquetaFem from '../../assets/imgs/home-jaqueta-feminina.png';
import detalheCinza from '../../assets/imgs/detalhe-cinza.png';
import detalheVerde from '../../assets/imgs/detalhe-verde-inverted.png';


const FemininaHomeSection = (props) => {

    return(
        <section className="d-xl-flex align-items-xl-center s-feminina"
                 style={{ background: `url(${ bgFeminina }) center / cover ` }}
        >
            <div className="container-fluid container-s-feminina" style={{ position: 'relative', zIndex: '11' }}>
                <div className="row">
                    <div
                        className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 d-xl-flex justify-content-xl-center align-items-xl-center"
                        data-aos="fade-right" data-aos-once="true"
                    >
                        <Link to="/produtos/jaquetas-femininas">
                            <h1 className="transit-400">JAQUETAS<br/>FEMININAS</h1>
                        </Link>
                    </div>
                    <div className="col d-xl-flex justify-content-xl-center align-items-xl-center">
                        <img
                            data-aos="fade-left"
                            data-aos-delay="100"
                            data-aos-once="true"
                            className="img-fluid" src={jaquetaFem}
                        />
                    </div>
                </div>
            </div>
            <div className="top-details"
                 style={{ background: `url(${ detalheCinza }) center` }}
            />
            <div className="bottom-details"
                 style={{ background: `url(${ detalheVerde }) center` }}
            />
        </section>
    );

}

export default FemininaHomeSection