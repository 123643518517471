// export const WEB_URLS = {
//     urlImg: 'http://localhost/jaquetasarizona/assets/img/',
//     urlJson: 'http://localhost/jaquetasarizona/',
//     urlApi: 'http://localhost/jaquetasarizona/api/'
// }

export const WEB_URLS = {
    urlImg: 'https://jaquetaarizona.com.br/assets/img/',
    urlJson: 'https://jaquetaarizona.com.br/arizona/',
    urlApi: 'https://jaquetaarizona.com.br/api/'
}
