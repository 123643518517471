import React, {useEffect} from "react";
import { connect } from "react-redux";
import SessionTop from "../components/session-top.component";
import bgTop from '../assets/imgs/colecao-bg.jpg';
import {addCurrentPage} from "../redux/utils/utils.actions";
import BoxPordutos from "../components/box-produtos.component";
import detalheVerdeBottom from "../assets/imgs/detalhe-verde-inverted.png";
import {NOVA_COLECAO} from "../utils/datas";
import ContatoBottom from "../components/contato-bottom.component";


const ColecaoPage = (props) => {

    let delayG = 0;
    const novaColecao = NOVA_COLECAO;

    useEffect(() => {
        scrollToTop()
        props.addCurrentPage("nova-colecao");
    }, []);

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "instant"
        });
    }

    return(
        <>
            <SessionTop bgTop={bgTop} flex={true}>
                <div className="container-fluid container-s-tops" style={{position: 'relative', zIndex: '11'}}>
                    <div className="row">
                        <div className="col-12" data-aos="fade-right" data-aos-once="true">
                            <h1 className="transit-400">COLEÇÃO 2021</h1>
                        </div>
                    </div>
                </div>
            </SessionTop>

            <section className="s-colecao produtos">
                <div className="container-title title-colecao">

                </div>

                <div className="container container-colecao">
                    <div className="row row-cols-1 row-cols-lg-3 justify-content-center">

                        {
                            novaColecao.length > 0 ?
                                novaColecao.map(item => {
                                    delayG += 50;
                                    return( <BoxPordutos key={item.id} produto={item} delay={delayG} /> );
                                })
                                :
                                <>
                                    <div className="col-12">
                                        <h2 style={{textAlign: 'center'}}>NENHUM PRODUTO ENCONTRADO</h2>
                                    </div>

                                </>
                        }

                    </div>

                </div>
                <div className="bottom-detail"
                     style={{ background: `url(${detalheVerdeBottom}) center`, backgroundRepeat: "no-repeat" }}
                />
            </section>

            <ContatoBottom />

        </>
    );

}

const mapDispatchToProps = dispatch => ({
    addCurrentPage: utils => dispatch(addCurrentPage(utils))
})

export default connect(null, mapDispatchToProps)(ColecaoPage);

