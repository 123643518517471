import { WEB_URLS } from "./configs";


const detalhesGeraisProd = "- Bordado frente, costas e manga <br/> - 01 Bolso interno <br/> - 02 Bolsos externos <br/> - Costurada com linha Preta Resistente <br/> - Forrada de nylon <br/> - Mangas removíveis <br/> - Fechamento com zíper e botões personalizado <br/> - Gola Alta";

export const HOME_CATS = [
    { id: 1, nome: 'Jaquetas', img:WEB_URLS.urlImg + 'cat-home-jaquetas.jpg', link:'/produtos/jaquetas-masculinas' },
    { id: 2, nome: 'Mochilas', img:WEB_URLS.urlImg + 'cat-home-mochilas.jpg', link:'/produtos/mochilas' },
    { id: 3, nome: 'Luvas', img:WEB_URLS.urlImg + 'cat-home-luvas.jpg', link:'/produtos/luvas' }
]

export const NOVA_COLECAO = [
    {
        id: 1,
        nome: 'Jaqueta Camuflada Cinza',
        imgCapa:WEB_URLS.urlImg + 'home-prod-1.png',
        detalhes: detalhesGeraisProd,
        imgs:[
            {original:  WEB_URLS.urlImg + 'home-prod-1.png', thumbnail: WEB_URLS.urlImg + 'home-prod-1.png'},
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-1-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-1-1.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-1-2.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-1-2.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-1-3.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-1-3.jpg'}
        ]
    },
    {
        id: 2,
        nome: 'Jaqueta Camuflada Verde',
        imgCapa:WEB_URLS.urlImg + 'home-prod-2.png',
        detalhes: detalhesGeraisProd,
        imgs:[
            {original:  WEB_URLS.urlImg + 'home-prod-2.png', thumbnail: WEB_URLS.urlImg + 'home-prod-2.png'},
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-2-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-2-1.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-2-2.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-2-2.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-2-3.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-2-3.jpg'}
        ]
    },
    {
        id: 3,
        nome: 'Jaqueta Modelo Básico',
        imgCapa:WEB_URLS.urlImg + 'home-prod-3.png',
        detalhes: detalhesGeraisProd,
        imgs:[
            {original:  WEB_URLS.urlImg + 'home-prod-3.png', thumbnail: WEB_URLS.urlImg + 'home-prod-3.png'},
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-8-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-8-1.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-8-2.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-8-2.jpg'}
        ]
    },
    {
        id: 4,
        nome: 'Jaqueta Modelo Refletiva',
        imgCapa:WEB_URLS.urlImg + 'home-prod-4.png',
        detalhes: detalhesGeraisProd,
        imgs:[
            {original:  WEB_URLS.urlImg + 'home-prod-4.png', thumbnail: WEB_URLS.urlImg + 'home-prod-4.png'},
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-7-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-7-1.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-7-2.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-7-2.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-7-3.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-7-3.jpg'}
        ]
    }
]


export const CATEGORIAS = [
    {
        id: 1,
        nome: 'Jaquetas <br/> Masculinas',
        nomeCat: 'Jaquetas Masculinas',
        url: 'jaquetas-masculinas',
        img: WEB_URLS.urlImg + 'prod-masc-circle.png',
        active: false
    },
    {
        id: 2,
        nome: 'Jaquetas <br/> Femininas',
        nomeCat: 'Jaquetas Femininas',
        url: 'jaquetas-femininas',
        img: WEB_URLS.urlImg + 'prod-fem-circle.png',
        active: false
    },
    {
        id: 5,
        nome: 'Capas de <br/> Chuva',
        nomeCat: 'Capas de Chuva',
        url: 'capas-chuva',
        img: WEB_URLS.urlImg + 'prod-capa-circle.png',
        active: false
    },
    {
        id: 3,
        nome: 'Mochilas',
        nomeCat: 'Mochilas',
        url: 'mochilas',
        img: WEB_URLS.urlImg + 'prod-mochi-circle.png',
        active: false
    },
    {
        id: 4,
        nome: 'Luvas',
        nomeCat: 'Luvas',
        url: 'luvas',
        img: WEB_URLS.urlImg + 'prod-luv-circle.png',
        active: false
    },
    {
        id: 6,
        nome: 'Pochetes',
        nomeCat: 'Pochetes',
        url: 'pochetes',
        img: WEB_URLS.urlImg + 'prod-pochete-circle.png',
        active: false
    }
]



export const PRODUTOS = [
    {
        id: 1,
        cat: 'jaquetas-masculinas',
        nome: 'Camuflada Cinza',
        imgCapa:WEB_URLS.urlImg + 'produtos/masculinas/prod-1-1.jpg',
        detalhes: detalhesGeraisProd,
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-1-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-1-1.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-1-2.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-1-2.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-1-3.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-1-3.jpg'}
        ]
    },
    {
        id: 2,
        cat: 'jaquetas-masculinas',
        nome: 'Camuflada Verde',
        imgCapa:WEB_URLS.urlImg + 'produtos/masculinas/prod-2-1.jpg',
        detalhes: detalhesGeraisProd,
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-2-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-2-1.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-2-2.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-2-2.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-2-3.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-2-3.jpg'}
        ]
    },
    {
        id: 3,
        cat: 'jaquetas-masculinas',
        nome: 'Corta Vento',
        imgCapa:WEB_URLS.urlImg + 'produtos/masculinas/prod-3-1.jpg',
        detalhes: detalhesGeraisProd,
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-3-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-3-1.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-3-2.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-3-2.jpg'}
        ]
    },
    {
        id: 29,
        cat: 'jaquetas-masculinas',
        nome: 'Corta Vento Camuflada Verde',
        imgCapa:WEB_URLS.urlImg + 'produtos/masculinas/corta-vento-cam-verde.jpg',
        detalhes: detalhesGeraisProd,
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/corta-vento-cam-verde.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/corta-vento-cam-verde.jpg'}
        ]
    },
    {
        id: 30,
        cat: 'jaquetas-masculinas',
        nome: 'Corta Vento Camuflada Cinza',
        imgCapa:WEB_URLS.urlImg + 'produtos/masculinas/corta-vento-cam-cinza.jpg',
        detalhes: detalhesGeraisProd,
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/corta-vento-cam-cinza.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/corta-vento-cam-verde.jpg'}
        ]
    },
    {
        id: 4,
        cat: 'jaquetas-masculinas',
        nome: 'Preta Com Azul',
        imgCapa:WEB_URLS.urlImg + 'produtos/masculinas/prod-4-1.jpg',
        detalhes: detalhesGeraisProd,
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-4-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-4-1.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-4-2.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-4-2.jpg'}
        ]
    },
    {
        id: 5,
        cat: 'jaquetas-masculinas',
        nome: 'Preta Com Cinza',
        imgCapa:WEB_URLS.urlImg + 'produtos/masculinas/prod-5-1.jpg',
        detalhes: detalhesGeraisProd,
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-5-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-5-1.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-5-2.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-5-2.jpg'}
        ]
    },
    {
        id: 6,
        cat: 'jaquetas-masculinas',
        nome: 'Preta Com Vermelho',
        imgCapa:WEB_URLS.urlImg + 'produtos/masculinas/prod-6-1.jpg',
        detalhes: detalhesGeraisProd,
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-6-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-6-1.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-6-2.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-6-2.jpg'}
        ]
    },
    {
        id: 7,
        cat: 'jaquetas-masculinas',
        nome: 'Refletiva',
        imgCapa:WEB_URLS.urlImg + 'produtos/masculinas/prod-7-1.jpg',
        detalhes: detalhesGeraisProd,
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-7-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-7-1.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-7-2.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-7-2.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-7-3.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-7-3.jpg'}
        ]
    },
    {
        id: 8,
        cat: 'jaquetas-masculinas',
        nome: 'Tradicional',
        imgCapa:WEB_URLS.urlImg + 'produtos/masculinas/prod-8-1.jpg',
        detalhes: detalhesGeraisProd,
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-8-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-8-1.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-8-2.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-8-2.jpg'}
        ]
    },
    {
        id: 9,
        cat: 'jaquetas-masculinas',
        nome: 'Sem Logo',
        imgCapa:WEB_URLS.urlImg + 'produtos/masculinas/prod-9-1.jpg',
        detalhes: detalhesGeraisProd,
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-9-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-9-1.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/masculinas/prod-9-2.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/masculinas/prod-9-2.jpg'}
        ]
    },
    {
        id: 10,
        cat: 'jaquetas-femininas',
        nome: 'Tradicional',
        imgCapa:WEB_URLS.urlImg + 'produtos/femininas/prod-f-1-1.jpg',
        detalhes: detalhesGeraisProd,
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/femininas/prod-f-1-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/femininas/prod-f-1-1.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/femininas/prod-f-1-2.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/femininas/prod-f-1-2.jpg'}
        ]
    },
    {
        id: 11,
        cat: 'jaquetas-femininas',
        nome: 'Refletiva ',
        imgCapa:WEB_URLS.urlImg + 'produtos/femininas/prod-f-2-1.jpg',
        detalhes: detalhesGeraisProd,
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/femininas/prod-f-2-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/femininas/prod-f-2-1.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/femininas/prod-f-2-2-new.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/femininas/prod-f-2-2-new.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/femininas/prod-f-2-3.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/femininas/prod-f-2-3.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/femininas/prod-f-2-4.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/femininas/prod-f-2-4.jpg'},
        ]
    },
    {
        id: 12,
        cat: 'mochilas',
        nome: 'Arizona Cinza',
        imgCapa:WEB_URLS.urlImg + 'produtos/mochilas/prod-m-1-1.jpg',
        detalhes: "",
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-1-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-1-1.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-1-2.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-1-2.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-1-3.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-1-3.jpg'}
        ]
    },
    {
        id: 13,
        cat: 'mochilas',
        nome: 'Arizona Preta',
        imgCapa:WEB_URLS.urlImg + 'produtos/mochilas/prod-m-2-1.jpg',
        detalhes: "",
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-2-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-2-1.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-2-2.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-2-2.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-2-3.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-2-3.jpg'}
        ]
    },
    {
        id: 20,
        cat: 'mochilas',
        nome: 'Monster',
        imgCapa:WEB_URLS.urlImg + 'produtos/mochilas/prod-m-9-1.jpg',
        detalhes: "",
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-9-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-9-1.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-9-2.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-9-2.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-9-3.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-9-3.jpg'}
        ]
    },
    {
        id: 14,
        cat: 'mochilas',
        nome: 'BMW Azul',
        imgCapa:WEB_URLS.urlImg + 'produtos/mochilas/prod-m-3-1.jpg',
        detalhes: "",
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-3-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-3-1.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-3-2.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-3-2.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-3-3.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-3-3.jpg'}
        ]
    },
    {
        id: 15,
        cat: 'mochilas',
        nome: 'BMW Preta',
        imgCapa:WEB_URLS.urlImg + 'produtos/mochilas/prod-m-4-1.jpg',
        detalhes: "",
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-4-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-4-1.jpg'}
        ]
    },
    {
        id: 21,
        cat: 'mochilas',
        nome: 'Red Bull Azul',
        imgCapa:WEB_URLS.urlImg + 'produtos/mochilas/prod-m-10-1.jpg',
        detalhes: "",
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-10-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-10-1.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-10-2.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-10-2.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-10-3.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-10-3.jpg'}
        ]
    },
    {
        id: 17,
        cat: 'mochilas',
        nome: 'Honda Azul',
        imgCapa:WEB_URLS.urlImg + 'produtos/mochilas/prod-m-6-1.jpg',
        detalhes: "",
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-6-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-6-1.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-6-2.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-6-2.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-6-3.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-6-3.jpg'}
        ]
    },
    {
        id: 18,
        cat: 'mochilas',
        nome: 'Honda Preta',
        imgCapa:WEB_URLS.urlImg + 'produtos/mochilas/prod-m-7-1.jpg',
        detalhes: "",
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-7-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-7-1.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-7-2.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-7-2.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-7-3.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-7-3.jpg'}
        ]
    },
    {
        id: 19,
        cat: 'mochilas',
        nome: 'Honda Vermelha',
        imgCapa:WEB_URLS.urlImg + 'produtos/mochilas/prod-m-8-1.jpg',
        detalhes: "",
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-8-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-8-1.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-8-2.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-8-2.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-8-3.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-8-3.jpg'}
        ]
    },
    {
        id: 22,
        cat: 'mochilas',
        nome: 'Yamaha Azul',
        imgCapa:WEB_URLS.urlImg + 'produtos/mochilas/prod-m-11-1.jpg',
        detalhes: "",
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-11-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-11-1.jpg'}
        ]
    },
    {
        id: 23,
        cat: 'mochilas',
        nome: 'Yamaha Preta',
        imgCapa:WEB_URLS.urlImg + 'produtos/mochilas/prod-m-12-1.jpg',
        detalhes: "",
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-12-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-12-1.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-12-2.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-12-2.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-12-3.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-12-3.jpg'}
        ]
    },
    {
        id: 24,
        cat: 'mochilas',
        nome: 'Yamaha Vermelha',
        imgCapa:WEB_URLS.urlImg + 'produtos/mochilas/prod-m-13-1.jpg',
        detalhes: "",
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-13-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-13-1.jpg'}
        ]
    },
    {
        id: 25,
        cat: 'luvas',
        nome: 'Masculina',
        imgCapa:WEB_URLS.urlImg + 'produtos/luvas/prod-l-1-1.jpg',
        detalhes: "",
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/luvas/prod-l-1-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/luvas/prod-l-1-1.jpg'}
        ]
    },
    {
        id: 26,
        cat: 'luvas',
        nome: 'Feminina',
        imgCapa:WEB_URLS.urlImg + 'produtos/luvas/prod-l-2-1.jpg',
        detalhes: "",
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/luvas/prod-l-2-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/luvas/prod-l-2-1.jpg'}
        ]
    },
    {
        id: 16,
        cat: 'mochilas',
        nome: 'Harley Davidson',
        imgCapa:WEB_URLS.urlImg + 'produtos/mochilas/prod-m-5-1.jpg',
        detalhes: "",
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-5-1.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-5-1.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-5-2.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-5-2.jpg'},
            {original:  WEB_URLS.urlImg + 'produtos/mochilas/prod-m-5-3.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/prod-m-5-3.jpg'}
        ]
    },
    ,
    {
        id: 27,
        cat: 'pochetes',
        nome: 'Nylon',
        imgCapa:WEB_URLS.urlImg + 'produtos/pochetes/pochete-basica.jpg',
        detalhes: "",
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/pochetes/pochete-basica.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/produtos/pochetes/pochete-basica.jpg'}
        ]
    },
    {
        id: 28,
        cat: 'pochetes',
        nome: 'Básica',
        imgCapa:WEB_URLS.urlImg + 'produtos/pochetes/pochete-nylon.jpg',
        detalhes: "",
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/pochetes/pochete-nylon.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/mochilas/produtos/pochetes/pochete-nylon.jpg'}
        ]
    },
    {
        id: 31,
        cat: 'capas-chuva',
        nome: 'Capa de Chuva',
        imgCapa:WEB_URLS.urlImg + 'produtos/capa-chuva/capa-chuva-1-1-new.jpg',
        detalhes: "",
        imgs:[
            {original:  WEB_URLS.urlImg + 'produtos/capa-chuva/capa-chuva-1-1-new.jpg', thumbnail: WEB_URLS.urlImg + 'produtos/capa-chuva/capa-chuva-1-1-new.jpg'}
        ]
    }


];